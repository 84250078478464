import 'magnific-popup';

export default function initPopup() {
    $(document).ready(() => {
        $('.popup-link').magnificPopup({
            type: 'inline',
            midClick: true,
            mainClass: 'mfp-fade',
            showCloseBtn: false,
            fixedBgPos: true,
        });
        onClosePopupClick();
    });
}

export function onClosePopupClick() {
    $(document).on('click', '.popup__link_close', (e) => {
        e.preventDefault();
        $.magnificPopup.close();
    });
}


export function openDynamicPopup(title, text, params, modif) {
    $.magnificPopup.open({
        items: {
            src: '<div class="popup popup' + modif + '"><h3 class="popup__header">' + title + '</h3><p class="popup__subheader">' + text + '</p><div class="popup__btns popup__btns_single"><button class="btn btn_green has-ripple popup__link popup__link_close">Закрыть</button></div></div>',
            type: 'inline',
        },
        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
        callbacks: {
            close: function () { if (params && params.reload) window.location.reload(); },
            open: onClosePopupClick(),
        }
    });
}
