import 'magnific-popup';
import '../../libs/materialripple/js/jquery.materialripple';
import '../../libs/perfect-scrollbar/js/perfect-scrollbar.jquery.min';
import Fingerprint2 from 'fingerprintjs2';
import { get, post } from 'axios';



import { cookieUtils, openInfoPopup, initCommonPopup, showHelpTooltip, getUrlParameter } from '../utils';
import { openPartnerPopup } from '../partner-modal'
import { LOGIN_COOKIE } from '../constants';

import { callLiveTex } from '../../blocks/modules/_common/online-support/online-support';
import { renderIdenticon } from '../../blocks/modules/_common/avatar/avatar';
import { getNavigatorInfo } from '../navg';
import pushModal from '../push_notifications';
import goldModal from '../../blocks/modules/_common/gold/gold';
import { togglBonusesSpins } from '../../blocks/modules/_common/header/_logged/header-spins/header-spins';


if (!window.standAlone) {
    pushModal('popup_push');
}

window.openInfoPopup = openInfoPopup;
/**
 * Show modal with email confirm after registration
 */
/*function showMailConfirmModal() {
    const data = JSON.parse(localStorage.getItem('confirm_email'));
    const linkToMail = document.querySelector('.confirm-email__link');

    if (data) {
        localStorage.removeItem('confirm_email');
        linkToMail.setAttribute('href', data.link);
    }
    else {
        linkToMail.remove();
    }

    $.magnificPopup.open({
        items: {
            src: '#verification-email',
        },
        type: 'inline',
        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
    });

    document.querySelector('#verification-email .form__link').addEventListener('click', function (e) {
        $.magnificPopup.close();
    })
}*/

function clearValidationMessages() {
    $('.form-block__input_error').removeClass('form-block__input_error');
    $('.form-block__error').remove();
    $('.form-block__input_success').removeClass('form-block__input_success');
    $('.form-block__success').remove();
}

function createValidationMessages(object, error, message) {
    if (!error) {
        $(object).addClass('form-block__input_success');
        $(object).after('<span class="form-block__success">' + message + '</span>');
    } else {
        $(object).addClass('form-block__input_error');
        $(object).siblings('.form-block__error').remove();
        $(object).after('<span class="form-block__error">' + message + '</span>');
    }
}

(function ($) {
    $.fn.serializeFormJSON = function () {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function () {
            if (o[this.name]) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };
})(jQuery);

// showMailConfirmModal();

$(function () {
    $('.info-popup__button-close').click(() => {
        $.magnificPopup.close();
    });

    /**
     * Material buttons effect
     */
    $('.btn, .button-ripple, .tabs__tab-item_pay-system').not('.btn_online-support')
        .materialripple();

    /**
     * Browsers detect
     */
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
    const isChromeMac = navigator.userAgent.indexOf('Mac') !== -1 && navigator.userAgent.indexOf('Chrome') !== -1;
    if (isSafari) {
        $('.main-menu .fa, .product .fa-basket, .text-block_conditions .list-reset, .oath-form__button').addClass('safari');
    }

    if (isChromeMac) {
        $('.main-menu .fa-jackpot').addClass('chrome-mac');
    }

    /**
     * Change or restore password
     */
    $('#form_restore_password-popup').submit(function (e) {
        e.preventDefault();
        clearValidationMessages();
        const data = $(this).serializeFormJSON();
        $.ajax({
            type: 'POST',
            url: "/service/player/restorePassword",
            data: JSON.stringify(data),
            dataType: "json",
            contentType: "application/json",
            success: function (msg) {
                if (!msg.error) {
                    $('#change-pass .mfp-close').click();

                    $.magnificPopup.open({
                        items: {
                            src: '#pass-changed',
                        },
                        type: 'inline',
                        closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
                        callbacks: {
                            close: function close() {
                                if (!window.userLogged) {
                                    window.location.replace('/');
                                }
                            },
                        }

                    });
                } else {
                    $.each(msg.error.items, function (i, val) {
                        createValidationMessages($('#new-pass'), true, val);
                    });
                }
            },
            error: function (request, status, error) {
                console.log(error);
                $.each(request.error.items, function (i, val) {
                    createValidationMessages($('#new-pass'), true, val)
                });
            },
        });
    });
});


/**
 * Fingerprint
 */
(function fingerprint() {
    try {
        const options = {
            excludeAdBlock: true,
            excludeScreenResolution: true,
            excludeAvailableScreenResolution: true,
            excludePlugins: true
        };

        const navg = getNavigatorInfo();

        const fp = new Fingerprint2(options);
        fp.get(function (code) {
            const img = document.createElement('img');
            img.src = `/fingerprint?code=${code}&amp;nocache=${new Date().getTime()}`;
            img.classList.add('fingerprint-img');
            document.body.appendChild(img);
        });

        const fp2 = new Fingerprint2(options);
        fp2.get(code => post('/service/qstat', { fp: code, navg, uri: window.location.href }));
    }
    catch (e) {
        console.warn('Fingerprint blocked!')
    }
}())

import cssMap from '../../../../../resources/css.json';

async function loadStyles() {
    const prefix = '/css/';

    const pathMainCss = prefix + cssMap['main.min.css'];
    const pathIconCss = prefix + cssMap['icon.min.css'];
    const pathFontCss = prefix + cssMap['fonts.min.css'];

    function setStyles(styles) {
        const { data } = styles;
        const styleTag = document.createElement('style');
        styleTag.textContent = data;
        document.head.appendChild(styleTag);
    }

    function fallbackStyles(url) {
        const linkTag = document.createElement('link');
        linkTag.rel = 'stylesheet'
        linkTag.href = url;
        document.head.appendChild(linkTag);
    }

    try {
        let event = {};

        const fontCss = await get(pathFontCss);
        const iconCss = await get(pathIconCss);
        const mainCss = await get(pathMainCss);

        setStyles(fontCss);
        setStyles(iconCss);
        setStyles(mainCss);
        document.head.removeChild(document.head.querySelector('style'));

        if ( typeof(Event) === 'function') {
            event = new Event('stylesloaded');
        } else {
            event = document.createEvent('Event');
            event.initEvent('stylesloaded', true, false);
        }

        document.body.dispatchEvent(event);
    }
    catch (e) {
        fallbackStyles(pathFontCss);
        fallbackStyles(pathIconCss);
        fallbackStyles(pathMainCss);
        document.head.removeChild(document.head.querySelector('style'));
    }
}

(function deletePreloader() {
    window.addEventListener('load', () => {
        if (document.getElementById('init')) {
            document.body.removeChild(document.getElementById('init'));
            document.head.removeChild(document.head.querySelector('style'));
        }

        if (location.hash) {
            // setTimeout(() => window.scrollTo(0,0), 0);

            const anchor = [
                '#pay',
                '#out',
                '#conf',
                '#qiwi',
                '#visa',
                '#piastrix',
                '#yandex-money',
                '#webmoney',
                '#alfa',
                '#mobile',
                '#perfectmoney',
                '#mobile',
                '#privat24',
                '#bitcoin',
                '#sorvibank_rules',
            ];
        }

        if (location.href.includes('cashier')) {
            const event = new CustomEvent('afterload', {
                detail: { isReady: true },
            });
            document.dispatchEvent(event);
        }

        if (!window.userLogged) {
            const loginBtn = document.getElementById('btn_header_login');
            const regBtn = document.getElementById('btn_header_reg');

            if (cookieUtils.get(LOGIN_COOKIE)) {
                loginBtn.click();
                cookieUtils.delete(LOGIN_COOKIE);
            }
            else if (getUrlParameter('modal') === 'signup') {
                regBtn.click();
            }
            else if (getUrlParameter('modal') === 'signin') {
                loginBtn.click();
            }
            else {
                openPartnerPopup()
            }
        }

        if (typeof perfectScrollbar !== 'undefined') {
            $('.scrollbar-container').perfectScrollbar('update');
        }
        callLiveTex();
    });
}());

function bindSupportButton(){
    const $button = $('.js-support-list');
    const $socialList = $button.find('.support-soc__list');
    if (window.innerWidth > 1360){
        $button.hover(function () {
                $socialList.stop(true).fadeTo(250, 1);
        }, function () {
                $socialList.fadeTo(1000, 0);
        });
    }
    else{
        $button.click(function () {
            $socialList.css('opacity', '1');
            $socialList.toggle();
        });

    }
}

function changeGameLink() {
    const links = [...document.querySelectorAll('.js_game-link')];
    if (links.length > 0) {
        links.map(link => link.setAttribute('href', link.href.replace('demo', 'fun')));
    }
}

function isPerfectScrollbar() {
    $('.scrollbar-container').perfectScrollbar({
        wheelSpeed: 2,
        scrollYMarginOffset: 10,
        maxScrollbarLength: 190,
        suppressScrollX: true
    });
}

isPerfectScrollbar();


if (window.userLogged) {
    changeGameLink();
    togglBonusesSpins();
}

if (window.isNeedLoadStyles) { // pages with critical CSS
    loadStyles();
}

bindSupportButton();
renderIdenticon();
showHelpTooltip();

if (!window.location.href.includes('/game/')) {
    initCommonPopup();
}


if (window.userLogged && window.location.href.indexOf('/game/') === -1) {
    goldModal();
}

if (!Element.prototype.remove) {
    import(/* webpackChunkName: "element-remove" */ '../polyfills').then(fn => fn.polyfillElementRemove());
}

if (!FormData.prototype.entries) {
    import(/* webpackChunkName: "object-entries" */ '../polyfills').then(fn => fn.polyfillFormDataEntries());
}

if (!Promise) {
    import(/* webpackChunkName: "promise-polyfill" */ '../polyfills').then(fn => fn.polyfillPromise());
}
