import 'magnific-popup';
import { post, get } from 'axios';

import { openDynamicPopup } from '../popups/popups';
import { clearValidationMessages, isNumeric, simpleHandleError } from '../../../../js/utils';
import { currencyIcon } from '../../../../blocks/modules/_common/i18n/i18n';
import { URL_GOLD_EXCHANGE, GOLD_COEFFICIENT } from '../../../../js/constants';

const ERR_EMPTY = 'Недостаточно GOLD';
const ERR_NOT_NUMBER = 'Введите число';
const EXCHANGE_TITLE = 'Обмен GOLD';
const EXCHANGE_SUCCESS = 'Обмен произведен успешно';
const EXCHANGE_ERROR = 'Обмен не произошел';
let EXCHANGE_RATE = null;

export default function goldModal() {
    function exchangeGold(exchangeRate = 0.05) {
        const form = document.getElementById('gold_form');
        const input = document.getElementById('gold_count');
        const output = document.getElementById('gold_money_count');
        const btn = document.getElementById('btn_change');

        let playerGoldCount = parseInt(input.dataset.gold, 10);

        let isError = false;

        function showError(msg) {
            isError = true;
            btn.setAttribute('disabled', 'true');
            output.value = 0;

            input.classList.add('form-block__input_error');
            $(input).after(`<span class="form-block__error">${msg}</span>`);
        }

        function isValidationInput(e) {
            const unacceptableSymbols = ['.', ',', '-', '+'];
            if (unacceptableSymbols.includes(e.key)) {
                e.preventDefault();
            }
        }

        function goldExchangeRound(number) {
            return Math.round(number * 100) / 100;
        }

        function updatePlayerBalance(balance, currency = currencyIcon) {
            const playerBalance = document.getElementById('header__realAmount');
            const balanceString = `${parseInt(balance, 10).toLocaleString()} ${currency()}`;
            playerBalance.innerHTML = balanceString;
            return balanceString;
        }

        function updatePlayerGold(gold) {
            const domGold = document.querySelectorAll('.js-gold-count');
            domGold.forEach((el) => {
                el.textContent = gold.toLocaleString();
            });
        }

        function handleInputChange() {
            if (isError) {
                clearValidationMessages();
            }

            const goldForExchange = input.value.startsWith('0') ? 0 : parseInt(input.value, 10);
            const goldExchangeAmount = goldForExchange * exchangeRate;

            if (!isNumeric(goldForExchange)) {
                showError(ERR_NOT_NUMBER);
                return;
            }

            if (goldForExchange === 0) {
                showError(ERR_NOT_NUMBER);
                return;
            }

            if (goldForExchange > playerGoldCount) {
                showError(ERR_EMPTY);
                return;
            }

            isError = false;
            output.value = goldExchangeRound(goldExchangeAmount);
            btn.removeAttribute('disabled');
        }

        function handleExchangeCommit(res) {
            btn.removeAttribute('disabled');

            if (!res.data) {
                const errors = Object.values(res.error.items);
                openDynamicPopup(EXCHANGE_ERROR, errors, '', '_gold');
                return;
            }

            openDynamicPopup(EXCHANGE_TITLE, EXCHANGE_SUCCESS, '', '_gold');

            const { gold, balance } = res.data;
            const playerExchangeGold = parseInt(gold, 10);

            input.value = playerExchangeGold;

            updatePlayerBalance(balance);
            updatePlayerGold(playerExchangeGold);

            input.dataset.gold = playerExchangeGold;
            playerGoldCount = playerExchangeGold;

            handleInputChange();
        }

        function handleFormSubmit(e) {
            e.preventDefault();

            if (isError) {
                return;
            }

            btn.setAttribute('disabled', 'true');

            const gold = parseInt(input.value, 10);
            const postData = { gold };

            post(URL_GOLD_EXCHANGE, postData)
                .then(res => res.data)
                .then(data => handleExchangeCommit(data))
                .catch(err => simpleHandleError(err));
        }

        form.addEventListener('submit', handleFormSubmit);
        input.addEventListener('input', handleInputChange);
        input.addEventListener('focus', clearValidationMessages);
        input.addEventListener('keydown', isValidationInput, false);

        input.value = playerGoldCount;
        handleInputChange();
    }

    async function showExchangeGoldModal() {
        try {
            const popupInfo = document.querySelector('#exchange-gold');
            if (!EXCHANGE_RATE) {
                const { data : { data: rate } } = await get(GOLD_COEFFICIENT);
                EXCHANGE_RATE = rate;
                const popupExchangeRate = document.getElementById('exchange_rate');
                popupExchangeRate.innerHTML = rate;
                exchangeGold(EXCHANGE_RATE);
            }

            $.magnificPopup.open({
                items: {
                    src: popupInfo,
                },
                type: 'inline',
                closeMarkup: '<i class="mfp-close fa fa-cancel"></i>'
            });

        } catch (error) {
            simpleHandleError(error);
        }
    }

    $('#exchange__gold').click(function (e) {
        e.preventDefault();
        showExchangeGoldModal();
    });

    $(document).on('click', '.form-gold__link', (e) => {
        $.magnificPopup.close();
    });
}
