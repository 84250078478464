import { cookieUtils  } from './utils'


export function openPartnerPopup() {
    const DELAY_FIRST = 3000
    const DELAY_SECOND = 40000
    const PARTNER_COOKIE = 'rp'


    function updateRegTimeout() {
        localStorage.setItem('last_show_rp', Date.now())
    }

    function showRegPopup() {
        document.removeEventListener('click', handleClick)
        updateRegTimeout()

        const regBtn = document.getElementById('btn_header_reg')
        regBtn.click()
    }

    function handleClick(e) {
        e.preventDefault()
        showRegPopup()
    }

    function checkOpenPopup() {
        const rp = cookieUtils.get(PARTNER_COOKIE)

        if (!rp) {
            return
        }

        const currentSession = cookieUtils.get('currentSession')
        const lastShowRp = localStorage.getItem('last_show_rp') && parseInt(localStorage.getItem('last_show_rp'), 10)

        if (lastShowRp == null) {
            setTimeout(showRegPopup, DELAY_FIRST)
        }
        else {
            const deltaTime = Date.now() - lastShowRp

            if (deltaTime > DELAY_SECOND && rp === currentSession) {
                document.addEventListener('click', handleClick)
            }
        }
    }

    checkOpenPopup()
}
