import 'magnific-popup';
import { get } from 'axios';

// TODO: Remove jquer.cookie by cookieUtils
import './jquery.cookie';
// import { cookieUtils } from './utils';

export default function pushModal(id) {
    const URL_PUSH_PARAMS = '/service/feature?feature=WEB_NOTIFICATIONS';

    let popupPush, popupPushBtnAccept, popupPushBtnReject, popupPushBtnClose;
    popupPush = document.getElementById(id);

    if (popupPush && !location.pathname.includes('cashier') && !location.pathname.includes('profile') 
        && !location.pathname.includes('game') && localStorage.getItem('pushAll') !== 'false') {
        getModalParams();
        popupPushBtnAccept = popupPush.querySelector('.popup_push__accept');
        popupPushBtnReject = popupPush.querySelector('.popup_push__reject');
    } else {
        return
    }

    const millisecondsToDays = time => time / 1000 / 60 / 60 / 24;

    let isPushEnabled = false;
    let pushHideTime = 300000;
    let pushDelayTime = 180000;
    let timerId = 0;
    const windowParams = {
        width: 500,
        height: 500
    };

    let windowUrl = '';

    const checkCookies = () => {
        const now = new Date();
        return (!$.cookie('push_n_wait') ||
            new Date($.cookie('push_n_wait')) <= now) && (!$.cookie('push_n_delay') ||
            new Date($.cookie('push_n_delay')) <= now);
    };

    function checkIsShow() {
        if (checkCookies()) {
            showModal();
        } else {
            timerId = setInterval(function () {
                if (checkCookies()) {
                    showModal();
                }
            }, 5000);
        }
    }

    function getModalParams() {
        get(URL_PUSH_PARAMS)
            .then(({ data }) => {
                if (data) {
                    isPushEnabled = data.enabled;

                    if (isPushEnabled) {
                        pushHideTime = data.popupHideTime;
                        pushDelayTime = data.popupDelayTime;
                        windowUrl = `${data.url}?platform=${data.platform}`;

                        if (!$.cookie('push_n_delay')) {
                            const expDate = new Date();
                            expDate.setMilliseconds(expDate.getMilliseconds() + pushDelayTime);
                            $.cookie('push_n_delay', expDate, { expires: millisecondsToDays(pushDelayTime) });
                        }

                        checkIsShow();
                    }
                }
            })
            .catch((error) => console.error(error))
    }


    function showModal() {
        clearInterval(timerId);
        $.magnificPopup.open({
            items: {
                src: '#popup_push',
            },
            type: 'inline',
            midClick: true,
            mainClass: 'mfp-fade',
            closeMarkup: '<i class="mfp-close fa fa-cancel"></i>',
            callbacks: {
                close: function close() {
                    if (localStorage['pushAll'] !== 'false') {
                        hideModalTemporary();
                    }
                },
            },
        });

        popupPushBtnAccept.addEventListener('click', subscribeToNotifications);
        popupPushBtnReject.addEventListener('click', hideModal);
    }

    function hideModal() {
        localStorage.setItem('pushAll', false);
        popupPushBtnAccept.removeEventListener('click', subscribeToNotifications);
        popupPushBtnReject.removeEventListener('click', hideModal);
        $.magnificPopup.close();
    }

    function hideModalTemporary() {
        let expDate = new Date();
        expDate.setMilliseconds(expDate.getMilliseconds() + pushHideTime);
        $.cookie('push_n_wait', expDate, {expires: millisecondsToDays(pushHideTime)});
    }

    function subscribeToNotifications() {
        hideModal();
        window.open(windowUrl, 'Всегда будь первым!', windowParams);
    }

    const rejectSubscription = function () {
        hideModal();
    };

};
