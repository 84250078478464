function getTypes(plugin) {
    let result = [];

    for (let i = 0; i < plugin.length; i++) {
        let type = plugin[i];
        result.push(type.type);
    }

    return result;
}

function getPlugins() {
    const plugins = navigator.plugins;
    let result = [];

    for (let i = 0; i < plugins.length; i++) {
        let plugin = plugins[i];
        result.push({name: plugin.name, filename: plugin.filename, types: getTypes(plugin)});
    }

    return result;
}

function getNavigatorInfo() {
    return JSON.stringify({
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        cookieEnabled: navigator.cookieEnabled,
        deviceMemory: navigator.deviceMemory,
        hardwareConcurrency: navigator.hardwareConcurrency,
        language: navigator.language,
        languages: navigator.languages,
        platform: navigator.platform,
        product: navigator.product,
        productSub: navigator.productSub,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor,
        plugins: getPlugins(),
    });
}

export { getNavigatorInfo }
