export const togglBonusesSpins = () => {
  const bonusesBtn = document.querySelector('#bonuses-spins-btn')

  if (bonusesBtn) {
    const bonusesContainer = document.querySelector('#bonuses-spins-container')

    bonusesBtn.addEventListener('click', () => {
      bonusesContainer.classList.toggle('hidden')
    })

    document.addEventListener('click', (event) => {
      const isClickInside = bonusesContainer.contains(event.target)
      const isClickBtn = bonusesBtn.contains(event.target)

      if (!isClickInside && !isClickBtn) {
        bonusesContainer.classList.add('hidden')
      }
    })
  }
}