import Identicon from 'identicon.js';

function getIdenticon(hash) {
    const options = {
        format: 'svg',
        background: [255, 255, 255, 255],
        margin: 0.2
    };
    const data = new Identicon(hash, options).toString();

    return 'data:image/svg+xml;base64,' + data;
}

function setIndenticon(elem) {
    const value = elem.dataset.avatar;
    if (value && value.length >= 15) {
        const src = getIdenticon(value);
        elem.setAttribute('src', src);
    }
}

function renderIdenticon() {
    const avatars = [...document.querySelectorAll('.avatar')];

    avatars.forEach(avatar => setIndenticon(avatar));
}

export { getIdenticon, renderIdenticon };
